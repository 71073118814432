import React, { useRef } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AgendaRowItem from './AgendaRowItem';
import AgendaRowItemMobile from './AgendaRowItemMobile';
import { Typography, Grid, Paper, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '../../Components/DialogWrapper';
import AgendaFull from './AgendaFull';
import moment from 'moment';
import momentTimezone from 'moment-timezone'; //eslint-disable-line
import prepareObject from '../../Utilities/newObjectFromArrayFieldsAgenda';
import { fallbackImages, eventId, aiEnabled } from '../../config';
import trackAction from '../../Store/Actions/tracker';
import {
  useGetEventQuery,
  useGetAgendasQuery,
  useLazyGetAITalksQuery,
} from '../../Services/queryApi';

// Configure Redux
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getUser } from '../../Store/Reducers/user';
import useQuery from '../../Utilities/useQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(2, 1, 2, 1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  header: {},
  inputControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  button: {
    color: 'white',
    backgroundColor: '#6f1a1d',
  },
  card: {
    maxWidth: 345,
  },
  day: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
}));

const Agenda = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const user = useSelector(getUser);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  const presentationId = query.get('presentation_id');

  const {
    data: event,
    isFetching: isEventFetching,
    isLoading: isEventLoading,
  } = useGetEventQuery(eventId);
  const [
    triggerGetAITalks,
    {
      data: aiSuggestedTalks,
      isFetching: isAITalksFetching,
      isLoading: isAITalksLoading,
    },
  ] = useLazyGetAITalksQuery({ eventId });

  // displaying briefcase is unused
  // const {
  //   data: briefcase,
  //   isFetching: isBriefcaseFetching,
  //   isLoading: isBriefcaseLoading,
  // } = useGetBriefcaseQuery(eventId);

  const {
    data: agenda,
    isFetching: isAgendaFetching,
    isLoading: isAgendaLoading,
    error: agendaError,
    isSuccess: agendaLoaded,
  } = useGetAgendasQuery({ eventId });

  const [selectedDate, setSelectedDate] = React.useState('');
  const [aiFiltering, setAiFiltering] = React.useState(false);

  const currentTimeRef = useRef<null | HTMLDivElement>(null);

  const scrollToCurrent = () => {
    if (currentTimeRef.current) {
      currentTimeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const event = {
      action: 'Viewed',
      resource_type: 'Agenda',
      resource_id: null,
      url_visited: '/agenda',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    dispatch(trackAction(event));
    scrollToTop();
    if (aiEnabled) {
      triggerGetAITalks({ eventId }, { preferCacheValue: true });
    }
  }, []);

  const toggleAgendaFullscreenOn = (event, id) => {
    const trackedEvent = {
      action: 'Clicked',
      resource_type: 'AgendaDetails',
      resource_id: id.abstract_id,
      url_visited: '/agenda',
      resource_account_id: id.account_id,
      resource_account_name: id.account_name,
      resource_name: id.title,
    };

    dispatch(trackAction(trackedEvent));
    history.push(`/agenda?presentation_id=${id.abstract_id}`);
  };

  const toggleAgendaFullscreenOff = () => {
    history.push(`/agenda`);
  };

  const handleAIFilter = () => {
    setAiFiltering(!aiFiltering);
  };

  // console.log('Render Agenda');

  const agendas = agenda?.data?.map((agenda) => ({
    ...agenda,
  }));

  let abstracts: any[] = [];
  let days: string[] = [];
  let rooms: string[] = [];

  for (let x = 0; x < agendas?.length; x++) {
    let abstract: any = agendas[x];

    abstract.date = moment(abstract.start_time).format('YYYY-MM-DD');

    if (abstract.start_time && abstract.accepted_type !== 'Workshop') {
      if (abstract.cleared_for_public_view === 'Yes - Approved') {
        abstracts.push(abstract);
      } else {
        if (
          user?.roles?.includes('Attendee') ||
          user?.roles?.includes('Pharma Attendee')
        ) {
          abstracts.push(abstract);
        }
      }
    }

    if (
      abstract.date &&
      !days.includes(abstract.date) &&
      abstract.start_time &&
      abstract.accepted_type !== 'Workshop'
    ) {
      days.push(abstract.date);
    }

    if (
      abstract.room &&
      !rooms.includes(abstract.room) &&
      abstract.start_time
    ) {
      if (abstract.room === 'FEATURED') {
        rooms.unshift(abstract.room);
      } else {
        rooms.push(abstract.room);
      }
    }
  }

  abstracts.sort((a, b) => {
    return (
      moment.duration(a.start_time).asMinutes() -
      moment.duration(b.start_time).asMinutes()
    );
  });

  days.sort();

  const currentDayIndex = days.findIndex(
    (day) => day.substring(0, 10) === moment().format('YYYY-MM-DD')
  );
  let startDate;
  if (currentDayIndex > -1) {
    startDate = days[currentDayIndex];
  } else {
    startDate = days[0];
  }

  let currentAgendaItem = abstracts.find(
    (abstract) => abstract.abstract_id === presentationId
  );

  const allRoutes = event.parsed_available_routes.routes;
  let pageTitle = '';
  allRoutes.forEach((route) => {
    if (route.route === '/agenda') {
      pageTitle = route.title;
    }
  });

  let loadingGraphic = (
    <Grid item xs={12} style={{ textAlign: 'center' }}>
      <br />
      <br />
      <CircularProgress color="inherit" />
      <Typography variant="h4" color="textPrimary">
        Loading...
      </Typography>
    </Grid>
  );

  let loadCompleteNoneFound = (
    <Grid item xs={12} style={{ textAlign: 'center' }}>
      <br />
      <br />
      <Typography variant="h6" color="textPrimary">
        No Agenda items found.
      </Typography>
    </Grid>
  );

  let referenceFound = false;

  let agendaItems = abstracts?.map((abstract, index) => {
    if (
      (selectedDate || startDate) ===
        moment(abstract.start_time).format('YYYY-MM-DD') &&
      event.parsed_topic_map
    ) {
      if (aiFiltering) {
        let abstractId = abstract.abstract_id.toString();
        if (!aiSuggestedTalks?.data?.includes(abstractId)) return null;
      }

      let requiredFields = [
        'description',
        'duration',
        'endDatetime',
        'location',
        'startDatetime',
        'title',
      ];
      // let requiredFields = ['description', 'duration', 'endDatetime', 'location', 'startDatetime', 'timezone', 'title']
      let calendarAbstract = prepareObject(
        abstract,
        requiredFields,
        'presentation'
      );

      let agendaListImage;
      if (abstract.supporting_file_url) {
        agendaListImage = abstract.supporting_file_url;
      } else if (event.parsed_topic_map[abstract.room]) {
        agendaListImage = event.parsed_topic_map[abstract.room];
      } else if (event.parsed_topic_map[abstract.topic]) {
        agendaListImage = event.parsed_topic_map[abstract.topic];
      } else {
        agendaListImage = fallbackImages.agendaCard;
      }

      // let fakeNow = moment('October 29 2022 10:55').format('HH:mm'); // testing purposes
      let currentTime = moment().format('HH:mm');
      let timeIsNow = false;
      if (
        currentTime > moment(abstract.start_time).format('HH:mm') &&
        currentTime < moment(abstract.end_time).format('HH:mm') &&
        !referenceFound
      ) {
        timeIsNow = true;
        referenceFound = true;
      }
      return (
        <div key={index} id={`abstract${index}`}>
          {timeIsNow ? (
            <span
              ref={currentTimeRef}
              style={{ position: 'relative', top: '-84px' }}
            />
          ) : null}
          {isMobile ? (
            <AgendaRowItemMobile
              key={index}
              abstract={abstract}
              topicMap={event.parsed_topic_map}
              calendarAbstract={calendarAbstract} // object needed to create calendar .ics
              roomImage={agendaListImage}
              selectedDate={selectedDate || startDate}
              toggleFullscreenHandlerOn={toggleAgendaFullscreenOn}
            />
          ) : (
            <AgendaRowItem
              key={index}
              abstract={abstract}
              topicMap={event.parsed_topic_map}
              calendarAbstract={calendarAbstract} // object needed to create calendar .ics
              roomImage={agendaListImage}
              selectedDate={selectedDate || startDate}
              toggleFullscreenHandlerOn={toggleAgendaFullscreenOn}
            />
          )}
        </div>
      );
    } else {
      return null;
    }
  });

  agendaItems = agendaItems.filter((item) => item);

  let timezoneGuess = (
    <Typography
      variant="h6"
      color="textSecondary"
      align="center"
      style={{ padding: '16px 0px' }}
    >
      All times displayed are in your local time zone. ({moment.tz.guess()})
    </Typography>
  );

  return (
    <React.Fragment>
      <Grid
        container
        direction={'row'}
        spacing={2}
        justifyContent="center"
        className={classes.header}
      >
        <Grid item xs={12}>
          <Paper>
            <Typography
              style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
              variant="h4"
              color="textPrimary"
            >
              {pageTitle}
            </Typography>
          </Paper>
        </Grid>
        {days.map((day, index) => {
          if (day === (selectedDate || startDate)) {
            return (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                key={index}
                onClick={() => setSelectedDate(day)}
                style={{ cursor: 'pointer' }}
              >
                <Paper
                  className={classes.paper + ' ' + classes.day}
                  variant="elevation"
                  elevation={10}
                >
                  <Typography variant="h5">
                    {moment(day).format('dddd, MMMM Do')}
                  </Typography>
                </Paper>
              </Grid>
            );
          } else {
            return (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                key={index}
                onClick={() => setSelectedDate(day)}
                style={{ cursor: 'pointer' }}
              >
                <Paper className={classes.paper}>
                  <Typography variant="h5">
                    {moment(day).format('dddd, MMMM Do')}
                  </Typography>
                </Paper>
              </Grid>
            );
          }
        })}
        {aiEnabled && aiSuggestedTalks?.data?.length ? (
          <>
            <Grid item xs={8}>
              {timezoneGuess}
            </Grid>
            <Grid item xs={4}>
              <Grid item container alignItems="flex-end" sm={12} md={3}>
                <Button onClick={handleAIFilter}>
                  All
                  <Switch
                    checked={aiFiltering}
                    onChange={handleAIFilter}
                    color="primary"
                    name="listFormatSwitch"
                    inputProps={{ 'aria-label': 'listFormatSwitch' }}
                  />
                  AI Suggested
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            {timezoneGuess}
          </Grid>
        )}
      </Grid>
      {moment().format('YYYY-MM-DD') === (selectedDate || startDate) ? (
        <div style={{ textAlign: 'center' }}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => scrollToCurrent()}
          >
            <Typography
              variant="button"
              // color="textSecondary"
              align="center"
              style={{ padding: '8px 64px', fontSize: '1.2rem' }}
            >
              Scroll to current time
            </Typography>
          </Button>
        </div>
      ) : null}
      {abstracts?.length <= 0 && !agendaLoaded ? loadingGraphic : null}
      {abstracts?.length <= 0 && agendaLoaded ? loadCompleteNoneFound : null}
      {aiEnabled && !agendaItems.length && aiFiltering ? (
        <Grid item xs={12} container alignItems="center">
          <Typography
            variant="h5"
            color="textSecondary"
            align="center"
            style={{ padding: '16px 0px' }}
          >
            There are no AI suggested talks for you at this time. Please select
            a different date!
          </Typography>
        </Grid>
      ) : null}
      {agendaItems}
      {currentAgendaItem && (
        <Dialog
          open={currentAgendaItem ? true : false}
          handleClose={toggleAgendaFullscreenOff}
          scroll="body"
        >
          <AgendaFull
            agenda={currentAgendaItem}
            closeAgenda={toggleAgendaFullscreenOff}
            // handleUpvote={() => this.handleUpvotePosterFull(this.state.agendaId)}
          />
        </Dialog>
      )}
      <div style={{ marginBottom: '16px' }} />
    </React.Fragment>
  );
};

export default Agenda;
