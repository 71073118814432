import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import LeadsAbstracts from './LeadsAbstracts';
import LeadsExhibitor from './LeadsExhibitor';

// Redux
import { connect } from 'react-redux';

const useStyles = (theme) => ({});

class Leads extends Component {
  render() {
    let pageContent;

    if (this.props.user.user.roles.toLowerCase().includes('poster presenter')) {
      pageContent = <LeadsAbstracts />;
    }
    if (this.props.user.user.roles.toLowerCase().includes('booth staff')) {
      pageContent = <LeadsExhibitor />;
    }
    if (this.props.user.user.roles.toLowerCase().includes('booth admin')) {
      pageContent = <LeadsExhibitor />;
    }

    return <React.Fragment>{pageContent}</React.Fragment>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    chat: state.chat,
    debug: state.debug,
    event: state.event,
    conversations: state.twilio.conversations,
  };
}

export default connect(mapStateToProps, null)(withStyles(useStyles)(Leads));
