import {
  Container,
  Box,
  Card,
  CardContent,
  MenuItem,
  Typography,
  Button,
  Select,
} from '@material-ui/core';
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import Cookie from 'js-cookie';

const countryList = ['US/Canada', 'Other'];

export default function LocationForm() {
  const [country, setCountry] = useState('US/Canada');
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      location: 'US/Canada',
    },
    onSubmit: (values) => {
      Cookie.set('location', values.location);
      history.push('/');
    },
  });

  const { setFieldValue } = formik;

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
    setFieldValue('location', event.target.value);
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="40vh"
      >
        <Card>
          <CardContent>
            <Typography variant="h5" align="center" gutterBottom>
              Please choose your location.
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="location"
                variant="outlined"
                value={country}
                onChange={handleChangeCountry}
              >
                {countryList.map((country, index) => {
                  return (
                    <MenuItem key={index} value={country}>
                      {country}
                    </MenuItem>
                  );
                })}
              </Select>
              <br />
              <br />
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
