import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tabs, Tab, Typography, Chip, Divider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DataTable } from '../../Utilities/DataTable';
import {
  attendeeGridOptions,
  exhibitorGridOptions,
} from './AttendeeGridOptions';
import axios from 'axios';
import { eventId, accountId } from '../../config';
import { useSelector } from 'react-redux';
import { useGetAttendeeDirectoryQuery } from '../../Services/queryApi';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textDecoration: 'none',
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
  },
  centerButtons: {
    textAlign: 'center',
    lineHeight: 1.25,
  },
  toolbar: {
    minHeight: '64px', //required to make mobile behave properly
  },
  tabs: {
    // display: 'inline-flex',
    display: 'flex',
    '& div.MuiTabs-scroller': {
      '& .MuiTabs-flexContainer': {
        justifyContent: 'center',
      },
    },
    marginBottom: theme.spacing(3),
  },
  tabSingle: {
    minWidth: 'auto',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function AttendeeList(props) {
  const classes = useStyles();
  const event = useSelector((state) => state.event.event);
  let syncServiceSid = event.twilio_sync_sid;

  const [attendeeList, setAttendeeList] = useState([]);
  const [exhibitorList, setExhibitorList] = useState([]);
  const [inPersonAttendees, setInPersonAttendees] = useState(0);
  const [virtualAttendees, setVirtualAttendees] = useState(0);
  const [inPersonExhibitors, setInPersonExhibitors] = useState(0);
  const [virtualExhibitors, setVirtualExhibitors] = useState(0);
  const [exhibitorsOfInterest, setExhibitorsOfInterest] = useState([]);
  const [topicCounts, setTopicCounts] = useState({});
  const [topicCountsOnSite, setTopicCountsOnSite] = useState({});
  const [tabValue, setTabValue] = useState(0);

  const { data, error, isLoading } = useGetAttendeeDirectoryQuery({
    eventId: eventId,
    syncServiceSid: syncServiceSid,
  });

  useEffect(() => {
    const initialize = async () => {
      if (data) {
        const attendees = [];
        const inPersonAttendees = [];
        let ipac = 0;
        let vac = 0;
        let ipec = 0;
        let vec = 0;
        const virtualAttendees = [];
        const inPersonExhibitors = [];
        const virtualExhibitors = [];
        const exhibitors = [];
        const exhibitorsOfInterest = [];
        const topicsOfInterest = [];
        const topicsOfInterestOnSite = [];
        const topicCounts = {};
        const topicCountsOnSite = {};
        data.forEach((person) => {
          let personEdited = { ...person };
          if (personEdited.roles.includes('Attendee')) {
            personEdited.custom_attributes?.forEach((custom) => {
              if (Object.keys(custom).toString().includes('tracks')) {
                let keys = Object.keys(custom)[0];
                let values = Object.values(custom)[0];
                let parseNeeded = false;
                let newValues = null;
                if (values !== null) {
                  if (values.includes('{')) {
                    parseNeeded = true;
                  }
                  values = values
                    .replace('{', '[')
                    .replace('}', ']')
                    .split(',');
                  if (parseNeeded) {
                    newValues = JSON.parse(`${values}`);
                  } else {
                    newValues = values;
                  }
                  // sanofi specific due to topic that has commas
                  newValues = newValues
                    .map((value) => {
                      if (value.indexOf(' ') === 0) {
                        return null;
                      }
                      if (value === 'Analytical Methods for LNPs') {
                        return 'Analytical Methods for LNPs, mRNA, Cell & Gene Therapies';
                      }
                      return value;
                    })
                    .filter((value) => value);
                  personEdited.interest_topic = {
                    question: keys,
                    answer: newValues,
                  };
                  topicsOfInterest.push(...newValues);
                  if (personEdited.days.includes('Day')) {
                    topicsOfInterestOnSite.push(...newValues);
                  }
                } else {
                  personEdited.interest_topic = null;
                }
              }
              if (Object.keys(custom).toString().includes('Exhibitor')) {
                let keys = Object.keys(custom)[0];
                let values = Object.values(custom)[0];
                let parseNeeded = false;
                let newValues = null;
                if (values !== null) {
                  if (values.includes('{')) {
                    parseNeeded = true;
                  }
                  values = values
                    .replace('{', '[')
                    .replace('}', ']')
                    .split(',');
                  if (parseNeeded) {
                    newValues = JSON.parse(`${values}`);
                  } else {
                    newValues = values;
                  }
                  newValues = newValues.filter((value) => value);
                  personEdited.interest_exhibitor = {
                    question: keys,
                    answer: newValues,
                  };
                  exhibitorsOfInterest.push(...newValues);
                }
              } else {
                personEdited.interest_exhibitor = null;
              }
            });
            if (personEdited.account_id === accountId) {
              attendees.push(personEdited);
              if (personEdited.days.includes('Day')) {
                inPersonAttendees.push(personEdited);
                ipac = ipac + 1;
              } else {
                virtualAttendees.push(personEdited);
                vac = vac + 1;
              }
            }
          } else {
            if (personEdited.account_id !== '1691992000000189008') {
              // person's account is not PlanetConnect
              let rolesWithoutBoothAdmin = personEdited.roles
                .replace('Booth Admin,', '')
                .replace('Booth Admin', '')
                .trim();
              if (rolesWithoutBoothAdmin.length > 0) {
                exhibitors.push(personEdited);
                if (personEdited.days.includes('Day')) {
                  inPersonExhibitors.push(personEdited);
                  ipec = ipec + 1;
                } else {
                  virtualExhibitors.push(personEdited);
                  vec = vec + 1;
                }
              }
            }
          }
        });
        const uniqueExhibitorsOfInterest = [...new Set(exhibitorsOfInterest)];

        for (const num of topicsOfInterest) {
          topicCounts[num] = topicCounts[num] ? topicCounts[num] + 1 : 1;
        }
        for (const num of topicsOfInterestOnSite) {
          topicCountsOnSite[num] = topicCountsOnSite[num]
            ? topicCountsOnSite[num] + 1
            : 1;
        }

        setAttendeeList(attendees);
        setExhibitorList(exhibitors);
        setInPersonAttendees(ipac);
        setVirtualAttendees(vac);
        setInPersonExhibitors(ipec);
        setVirtualExhibitors(vec);
        setExhibitorsOfInterest(uniqueExhibitorsOfInterest);
        setTopicCounts(topicCounts);
        setTopicCountsOnSite(topicCountsOnSite);
      }
    };
    initialize().catch((error) => console.log(error));
  }, [data]);

  let attendeeTotals = (
    <>
      <Typography
        variant="body1"
        style={{ marginBottom: '16px', backgroundColor: '#F2F2F2' }}
      >
        In Person: {inPersonAttendees}
      </Typography>
      <Typography
        variant="body1"
        style={{ marginBottom: '16px', backgroundColor: '#F2F2F2' }}
      >
        Virtual Only: {virtualAttendees}
      </Typography>
      <Typography
        variant="body1"
        style={{ marginBottom: '16px', backgroundColor: '#F2F2F2' }}
      >
        Total: {attendeeList.length}
      </Typography>
    </>
  );

  let attendeeRender = (
    <>
      <Typography variant="h4" style={{ marginBottom: '16px' }}>
        Attendee List
      </Typography>
      {attendeeList?.length > 20 ? attendeeTotals : null}
      <DataTable
        initialGridOptions={attendeeGridOptions}
        initialRowData={attendeeList}
        type="attendee"
      />
      {attendeeTotals}
    </>
  );
  let exhibitorTotals = (
    <>
      <Typography
        variant="body1"
        style={{ marginBottom: '16px', backgroundColor: '#F2F2F2' }}
      >
        In Person: {inPersonExhibitors}
      </Typography>
      <Typography
        variant="body1"
        style={{ marginBottom: '16px', backgroundColor: '#F2F2F2' }}
      >
        Virtual Only: {virtualExhibitors}
      </Typography>
      <Typography
        variant="body1"
        style={{ marginBottom: '16px', backgroundColor: '#F2F2F2' }}
      >
        Total: {exhibitorList.length}
      </Typography>
    </>
  );
  let exhibitorRender = (
    <>
      <Typography variant="h4" style={{ marginBottom: '16px' }}>
        Exhibitor List
      </Typography>
      {exhibitorList?.length > 20 ? exhibitorTotals : null}

      <DataTable
        initialGridOptions={exhibitorGridOptions}
        initialRowData={exhibitorList}
        type="exhibitor"
      />
      {exhibitorTotals}
    </>
  );
  const topicsAndCounts = [];
  for (const property in topicCounts) {
    topicsAndCounts.push(
      <>
        <Typography variant="body1">
          {property}: {topicCounts[property]} ({topicCountsOnSite[property]})
        </Typography>
        <Divider className={classes.divider} variant="fullWidth" />
      </>
    );
  }
  let attendeeInterestQuestion = (
    <>
      <Typography variant="h4" style={{ marginBottom: '16px' }}>
        Exhibitors and Tracks of Interest
      </Typography>
      <Typography
        variant="h6"
        style={{ marginBottom: '16px', backgroundColor: '#F2F2F2' }}
      >
        Exhibitors of Interest
      </Typography>
      {exhibitorsOfInterest.map((ex, i) => {
        return (
          <Chip
            label={ex}
            size="large"
            variant="outlined"
            key={i}
            style={{ margin: '8px', padding: '16px 8px', fontSize: '1.2rem' }}
          />
        );
      })}
      <br />
      <br />
      <Typography variant="h6" style={{ backgroundColor: '#F2F2F2' }}>
        Tracks and number of attendees interested
      </Typography>
      <Typography
        variant="subtitle1"
        style={{ marginBottom: '16px', backgroundColor: '#F2F2F2' }}
      >
        <i>
          Format = Track name: Total interest count (On-site only interest
          count)
        </i>
      </Typography>
      {topicsAndCounts.map((topic) => topic)}
      <br />
      <br />
    </>
  );

  let tabSelection = (
    <Tabs
      className={classes.tabs}
      value={tabValue}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
    >
      <Tab
        className={classes.tabSingle}
        label={'Attendee'}
        onClick={() => setTabValue(0)}
      />
      <Tab
        className={classes.tabSingle}
        label={'Exhibitor'}
        onClick={() => setTabValue(1)}
      />
      <Tab
        className={classes.tabSingle}
        label={'Attendee Interests'}
        onClick={() => setTabValue(2)}
      />
    </Tabs>
  );

  let loadingGraphic = (
    <Grid item xs={12} style={{ textAlign: 'center' }}>
      <br />
      <br />
      <CircularProgress color="inherit" />
      <Typography variant="h4" color="textPrimary">
        Loading...
      </Typography>
    </Grid>
  );
  return (
    <>
      {attendeeList.length ? (
        <>
          {tabSelection}
          {tabValue === 0 && attendeeRender}
          {tabValue === 1 && exhibitorRender}
          {tabValue === 2 && attendeeInterestQuestion}
        </>
      ) : (
        loadingGraphic
      )}
    </>
  );
}
