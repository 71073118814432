import { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import Cookie from 'js-cookie';
// Redux

const CookieRoute = ({ path, exact, render }) => {
  const history = useHistory();

  useEffect(() => {
    if (!Cookie.get('location')) {
      history.push('/location');
    }
  }, []);

  return <Route path={path} exact={exact} render={render} />;
};

export default CookieRoute;
