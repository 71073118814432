import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Grid, TextField, Paper, Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NavBar from '../../Components/Navbar';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CheckIcon from '@material-ui/icons/Check';
import Hidden from '@material-ui/core/Hidden';
import Privacy from '../Privacy';
import Banner from '../Banner';
import DialogWrapper from '../../Components/DialogWrapper';
import {
  landingPage,
  eventId,
  accountId,
  allowedRegistrationDomains,
  allowedSigninDomains,
  MerckSSOEnabled,
  OktaSSOEnabled,
  landingpageOnly,
} from '../../config';
import {
  Link,
  // Redirect,
  withRouter,
} from 'react-router-dom';
import trackAction from '../../Store/Actions/tracker';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setAuthenticatedUser from '../../Store/Actions/setUser';
import setBriefcase from '../../Store/Actions/setBriefcase';
import { setLoginRedirect } from '../../Store/Reducers/user';
import setEventDetails from '../../Store/Actions/setEventDetails';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      width: '100%',
    },
  },
  gridBody: {
    marginTop: '60px',
  },
  bannerImage: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    position: 'relative',
    padding: 0,
    margin: 0,
    top: '-4px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: '80px',
  },
  formControl: {
    // margin: theme.spacing(2),
    minWidth: 120,
    width: '80%',
  },
  formControlButton: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '60%',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  toggleButton: {
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.light,
    },
    '& .Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  acceptButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    width: '40%',
    marginLeft: '5%',
    marginRight: '5%',
    fontSize: '1rem',
  },
  closeButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.light,
    width: '40%',
    marginLeft: '5%',
    marginRight: '5%',
    fontSize: '1rem',
  },
  privacyButton: {
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
});

class SSOonly extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ssoError: false,
      ssoSignedIn: false,
      ssoEmailChecked: false,
      code: null,
      // global state
      formSubmitted: false,
      globalError: false,
      submissionSuccess: false,
      submissionFailure: false,
      mustConfirmEmail: false,
      justSignedUp: false,
      federatedId: false,
      emailChecked: false,
      existingZohoRegistrant: false,
      existingCognitoUser: false,

      // registration fields
      registrationFields: [],

      // login
      newPasswordRequired: false,
      newPassword: '',

      // password
      password: '',
      passwordError: null,
      passwordErrorMessage: '',

      // privacy
      privacySelected: false,
      privacyOpen: false,
      privacyAgree: false,

      // first name
      firstName: '',
      firstNameError: null,
      firstNameErrorMessage: '',

      // last name
      lastName: '',
      lastNameError: null,
      lastNameErrorMessage: '',

      // email
      email: '',
      emailError: null,
      emailErrorMessage: '',

      // department
      department: '',
      departmentError: null,
      departmentErrorMessage: '',

      // job title
      jobTitle: '',
      jobTitleError: null,
      jobTitleErrorMessage: '',

      // company
      company: '',
      companyError: null,
      companyErrorMessage: '',

      // site
      site: '',
      siteError: null,
      siteErrorMessage: '',

      // phone
      phone: '',
      phoneError: null,
      phoneErrorMessage: '',

      // business address
      businessAddress: '',
      businessAddressError: null,
      businessAddressErrorMessage: '',

      // city
      city: '',
      cityError: null,
      cityErrorMessage: '',

      // state
      state: '',
      stateError: null,
      stateErrorMessage: '',

      // postal code
      postalCode: '',
      postalCodeError: null,
      postalCodeErrorMessage: '',

      // country
      country: '',
      countryError: null,
      countryErrorMessage: '',

      // custom1
      custom1: '',
      custom1Error: null,
      custom1ErrorMessage: '',

      // custom2
      custom2: '',
      custom2Error: null,
      custom2ErrorMessage: '',

      // custom3
      custom3: '',
      custom3Error: null,
      custom3ErrorMessage: '',

      // custom4
      custom4: '',
      custom4Error: null,
      custom4ErrorMessage: '',

      // custom5
      custom5: '',
      custom5Error: null,
      custom5ErrorMessage: '',

      // custom6
      custom6: '',
      custom6Error: null,
      custom6ErrorMessage: '',

      // question1
      question1: '',
      question1Error: null,
      question1ErrorMessage: '',

      // question2
      question2: '',
      question2Error: null,
      question2ErrorMessage: '',

      // question3
      question3: '',
      question3Error: null,
      question3ErrorMessage: '',

      // question4
      question4: '',
      question4Error: null,
      question4ErrorMessage: '',

      // find out
      findOut: '',
      findOutError: null,
      findOutErrorMessage: '',

      // get out
      getOut: '',
      getOutError: null,
      getOutErrorMessage: '',
    };
  }

  async componentDidMount() {
    if (this.props.location.search) {
      const query = new URLSearchParams(this.props.location.search);
      for (let param of query.entries()) {
        if (param[0] === 'error') {
          this.setState({
            ssoError: true,
          });
        }
      }
    }

    let user;
    await Auth.currentSession()
      .then((response) => {
        user = response;
      })
      .catch((error) => {
        console.log(error);
      });

    if (user) {
      await this.ssoCheckRegistrationStatus(user.idToken.payload.email);

      //  await this.trackSignIn()
      //  await this.props.setEventDetails()
      //  await this.props.setAuthenticatedUser()
      //  await this.props.setBriefcase()

      // //  window.open('/lobby')
    }
  }

  trackSignIn() {
    const event = {
      action: 'Entered',
      resource_type: 'LandingPages',
      resource_id: null,
      url_visited: '/sso',
      resource_account_id: null,
      resource_account_name: null,
      resource_name: null,
    };

    this.props.trackAction(event);
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event.target.value });
  }

  handleValidation = () => {
    this.setState({
      formSubmitted: true,
    });

    Auth.confirmSignUp(
      this.state.email.toLowerCase(),
      this.state.confirmationCode
    )
      .then((response) => {
        this.setState({
          userConfirmed: true,
          formSubmitted: false,
        });
      })
      .catch((error) => {
        this.setState({
          confirmationCodeError: true,
          confirmationCodeErrorMessage: 'Incorrect Confirmation Code',
          formSubmitted: false,
        });
      });
  };

  handleModal(value, inputIdentifier) {
    this.setState({ [inputIdentifier]: value });
  }

  checkEmail = async () => {
    this.setState({
      formSubmitted: true,
      emailError: false,
      emailErrorMessage: '',
      globalError: false,
    });

    if (!this.state.email) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: 'Please enter a valid email',
        globalError: true,
      });

      return;
    }

    if (this.state.email.includes('+')) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: "Email cannot contain a '+'",
        globalError: true,
      });

      return;
    }

    if (!this.state.email.includes('@') || !this.state.email.includes('.')) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: 'Email must contain: @ and .',
        globalError: true,
      });

      return;
    }

    if (this.state.email.includes(' ')) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: 'Email cannot contain a space',
        globalError: true,
      });

      return;
    }

    //logic to be used if we need to check the domain of the user's email on enterance
    let emailHasRequiredDomain = false;

    for (let i = 0; i < allowedSigninDomains.length; i++) {
      if (this.state.email.toLowerCase().includes(allowedSigninDomains[i])) {
        emailHasRequiredDomain = true;
      }
    }

    if (!emailHasRequiredDomain) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: 'Your user is not allowed at this event',
        globalError: true,
      });

      return;
    }

    let existingZohoRegistrant = false;
    let existingCognitoUser = false;
    // check to see if they are already a cognito user

    const existingRegistrant = await axios({
      method: 'GET',
      url: `/show-attendees/${eventId}/${this.state.email.toLowerCase()}`,
    });

    if (existingRegistrant.data.length) {
      if (
        existingRegistrant.data[0].email.toLowerCase() ===
        this.state.email.toLowerCase()
      ) {
        existingZohoRegistrant = true;
      }
    } else {
      existingZohoRegistrant = false;
    }

    const registrationFields = await axios({
      method: 'get',
      url: 'forms/' + eventId,
      // headers: { idtoken: data.idToken.jwtToken },
      params: { showId: eventId },
    })
      .then((response) => {
        let formObject = response.data.data;
        let registration = formObject.filter(
          (item) => item.type === 'registration'
        )[0];
        registration.fields = registration.fields
          .sort(function (a, b) {
            return a.field_id - b.field_id;
          })
          .filter((field) => field.is_enabled === true);
        let standardFields = registration.fields.filter(
          (field) => field.section === 'fieldsStandard'
        );
        let customFields = registration.fields.filter(
          (field) => field.section === 'fieldsCustom'
        );
        let questions = registration.fields.filter(
          (field) => field.section === 'question'
        );
        let requiredFields = registration.fields.filter(
          (field) => field.is_required === true
        );

        return [standardFields, customFields, questions, requiredFields];
      })
      .catch((error) => console.log(error));

    const code = '000000';

    await Auth.confirmSignUp(this.state.email.toLowerCase(), code, {
      forceAliasCreation: false,
    })
      .then((data) => console.log(data))
      .catch((err) => {
        switch (err.code) {
          case 'UserNotFoundException':
            existingCognitoUser = false;
            break;
          case 'NotAuthorizedException':
            existingCognitoUser = true;
            break;
          case 'AliasExistsException':
            existingCognitoUser = true;
            break;
          case 'CodeMismatchException':
            existingCognitoUser = true;
            break;
          case 'ExpiredCodeException':
            existingCognitoUser = true;
            break;
          default:
            existingCognitoUser = false;
            break;
        }
      });

    this.setState({
      formSubmitted: false,
      emailChecked: true,
      existingCognitoUser: existingCognitoUser,
      existingZohoRegistrant: existingZohoRegistrant,
      registrationFields: registrationFields,
    });
  };

  signIn = () => {
    this.setState({
      formSubmitted: true,
    });

    Auth.signIn(this.state.email.toLowerCase(), this.state.password)
      .then((response) => {
        if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.setState({
            newPasswordRequired: true,
            formSubmitted: false,
          });
        } else {
          this.trackSignIn();
          this.props.setEventDetails();
          this.props.setAuthenticatedUser();
          this.props.setBriefcase();
          if (this.props.user?.loginRedirect) {
            const loginRedirect = this.props.user.loginRedirect;
            this.props.setLoginRedirect();
            const redirectUrl = `${loginRedirect.pathname}${loginRedirect.search}`;
            console.log('redirecting to saved path', redirectUrl);
            this.props.history.push(
              redirectUrl,
              this.props.user.loginRedirect.state
            );
          } else {
            console.log('redirecting to /lobby');
            this.props.history.push('/lobby');
          }
        }
      })
      .catch((error) => {
        if (error.code === 'UserNotConfirmedException') {
          this.setState({
            formSubmitted: false,
            passwordError: true,
            passwordErrorMessage:
              'Please check your email for a confirmation link. If you have not recieved one, please check spam or contact support.',
          });
        } else {
          this.setState({
            formSubmitted: false,
            passwordError: true,
            passwordErrorMessage:
              'Incorrect password. If you forgot your pasword, you may reset it below be clicking on the "Reset Password" button.',
          });
        }
      });
  };

  setPermanentPassword = () => {
    this.setState({
      formSubmitted: true,
    });

    Auth.signIn(this.state.email.toLowerCase(), this.state.password)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          Auth.completeNewPassword(user, this.state.newPassword)
            .then((user) => {
              this.props.setEventDetails();
              this.props.setAuthenticatedUser();
              if (this.props.user?.loginRedirect) {
                const loginRedirect = this.props.user.loginRedirect;
                this.props.setLoginRedirect();
                const redirectUrl = `${loginRedirect.pathname}${loginRedirect.search}`;
                console.log('redirecting to saved path', redirectUrl);
                this.props.history.push(
                  redirectUrl,
                  this.props.user.loginRedirect.state
                );
              } else {
                console.log('redirecting to /lobby');
                this.props.history.push('/lobby');
              }
            })
            .catch((e) => {
              this.setState({
                emailError: true,
                emailErrorMessage: 'Unable to set permanent password',
                formSubmitted: false,
              });
            });
        } else {
          this.props.setEventDetails();
          this.props.setAuthenticatedUser();
          if (this.props.user?.loginRedirect) {
            const loginRedirect = this.props.user.loginRedirect;
            this.props.setLoginRedirect();
            const redirectUrl = `${loginRedirect.pathname}${loginRedirect.search}`;
            console.log('redirecting to saved path', redirectUrl);
            this.props.history.push(
              redirectUrl,
              this.props.user.loginRedirect.state
            );
          } else {
            console.log('redirecting to /lobby');
            this.props.history.push('/lobby');
          }
        }
      })
      .catch((e) => {
        this.setState({
          emailError: true,
          emailErrorMessage: 'Unable to set permanent password',
          formSubmitted: false,
        });
      });
  };

  handleCognitoSignup = () => {
    this.setState({
      formSubmitted: true,
      passwordError: false,
      passwordErrorMessage: '',
    });

    Auth.signUp({
      username: this.state.email.toLowerCase(),
      password: this.state.password,
      attributes: {
        email: this.state.email.toLowerCase(),
      },
    })
      .then((response) => {
        this.setState({
          mustConfirmEmail: true,
          existingCognitoUser: true,
          formSubmitted: false,
          justSignedUp: true,
        });
      })
      .catch((error) => {
        switch (error.code) {
          case 'UsernameExistsException':
            this.setState({
              emailError: true,
              emailErrorMessage: 'User already exists',
              formSubmitted: false,
            });
            break;
          case 'InvalidParameterException':
            this.setState({
              emailError: true,
              emailErrorMessage: 'Please ensure you are using a valid email',
              formSubmitted: false,
            });
            break;
          default:
            this.setState({
              passwordError: true,
              passwordErrorMessage:
                'Password must be at least 8 characters long.',
              formSubmitted: false,
            });
        }
      });
  };

  handleCountrySelect = (event) => {
    this.setState({
      country: event.target.value,
    });
  };

  handleStateSelect = (event) => {
    this.setState({
      state: event.target.value,
    });
  };

  federateSignIn() {
    Auth.federatedSignIn()
      .then((cred) => {
        // If success, you will get the AWS credentials
        return Auth.currentAuthenticatedUser();
      })
      .then((user) => {
        // If success, the user object you passed in Auth.federatedSignIn
      })
      .catch((e) => {
        console.log(e);
      });
  }

  ssoSignedInNavigation = async () => {
    await this.trackSignIn();
    await this.props.setEventDetails();
    await this.props.setAuthenticatedUser();
    await this.props.setBriefcase();
    // window.location.reload();
    // window.open('/lobby')
    if (landingpageOnly) {
      this.props.history.push('/');
    } else if (this.props.user?.loginRedirect) {
      this.props.setLoginRedirect();
      const redirectUrl = `${this.props.user.loginRedirect.path}${
        this.props.user.loginRedirect.search
          ? `?${this.props.user.loginRedirect.search}`
          : ''
      }`;
      console.log('redirecting to saved path', redirectUrl);
      this.props.history.push(redirectUrl, this.props.user.loginRedirect.state);
    } else {
      console.log('redirecting to /lobby');
      this.props.history.push('/lobby');
    }
  };

  ssoCheckRegistrationStatus = async (email) => {
    let existingZohoRegistrant = false;

    const userInfo = await Auth.currentUserInfo();
    // console.log('identities:', JSON.parse(userInfo.attributes.identities)[0]);
    // potentially check for SAML and use that rather than landingpageOnly

    const existingRegistrant = await axios({
      method: 'GET',
      url: `/show-attendees/${eventId}/${email.toLowerCase()}`,
    });

    if (existingRegistrant.data.length) {
      if (
        existingRegistrant.data[0].email.toLowerCase() === email.toLowerCase()
      ) {
        existingZohoRegistrant = true;
      }
    } else {
      existingZohoRegistrant = false;
    }

    let registrationFields;

    if (!existingZohoRegistrant) {
      registrationFields = await axios({
        method: 'get',
        url: 'forms/' + eventId,
        // headers: { idtoken: data.idToken.jwtToken },
        params: { showId: eventId },
      })
        .then((response) => {
          let formObject = response.data.data;
          let registration = formObject.filter(
            (item) => item.type === 'registration'
          )[0];
          registration.fields = registration.fields
            .sort(function (a, b) {
              return a.field_id - b.field_id;
            })
            .filter((field) => field.is_enabled === true);
          let standardFields = registration.fields.filter(
            (field) => field.section === 'fieldsStandard'
          );
          let customFields = registration.fields.filter(
            (field) => field.section === 'fieldsCustom'
          );
          let questions = registration.fields.filter(
            (field) => field.section === 'question'
          );
          let requiredFields = registration.fields.filter(
            (field) => field.is_required === true
          );

          return [standardFields, customFields, questions, requiredFields];
        })
        .catch((error) => console.log(error));
    }

    this.setState({
      // user attributes
      firstName: userInfo.attributes.given_name,
      lastName: userInfo.attributes.family_name,
      department: userInfo.attributes.zoneinfo,
      country: userInfo.attributes.locale,
      jobTitle: userInfo.attributes.profile,
      site: userInfo.attributes.address,

      //form attributes
      formSubmitted: false,
      ssoEmailChecked: true,
      existingZohoRegistrant: existingZohoRegistrant,
      registrationFields: registrationFields,
      ssoSignedIn: true,
      email: email,
    });
  };

  render() {
    const { classes } = this.props;

    if (this.state.ssoError) {
      return (
        <div>
          <NavBar />
          <div className={classes.grid}>
            <Grid
              className={classes.gridBody}
              container
              layout={'row'}
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Banner
                  banner_location={this.props.event.event.banner_location}
                />
                <Paper className={classes.paper}>
                  <Typography variant="h5" style={{ color: 'black' }}>
                    There's been an error logging you in.
                  </Typography>
                  <Typography variant="body1" style={{ color: 'black' }}>
                    Please ensure you are on your company's VPN. If the problem
                    persists, please contact support.
                  </Typography>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      onClick={() => this.setState({ ssoError: false })}
                      className={classes.button}
                    >
                      Return to Login
                    </Button>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }

    if (this.state.ssoSignedIn) {
      //   console.log('this.state.ssoSignedIn', this.state.ssoSignedIn);
      //   console.log(
      //     'this.state.existingZohoRegistrant',
      //     this.state.existingZohoRegistrant
      //   );

      return (
        <div>
          <NavBar />
          <div className={classes.grid}>
            <Grid
              className={classes.gridBody}
              container
              layout={'row'}
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Banner
                  banner_location={this.props.event.event.banner_location}
                />
                <Paper className={classes.paper}>
                  <Typography variant="h5" style={{ color: 'black' }}>
                    SSO Successful!
                  </Typography>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      onClick={() => this.ssoSignedInNavigation()}
                      className={classes.button}
                    >
                      {landingpageOnly
                        ? 'View Event Details and Register'
                        : 'Enter Virtual Event'}
                    </Button>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }

    if (this.state.newPasswordRequired) {
      return (
        <div>
          <NavBar />
          <div className={classes.grid}>
            <Grid
              className={classes.gridBody}
              container
              layout={'row'}
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Banner
                  banner_location={this.props.event.event.banner_location}
                />
                <Paper className={classes.paper}>
                  <Typography variant="h5" style={{ color: 'black' }}>
                    Create Permanent Password
                  </Typography>
                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      onChange={(event) => this.handleChange(event, 'email')}
                      value={this.state.email}
                      label="Email"
                      error={this.state.emailError}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      onChange={(event) =>
                        this.handleChange(event, 'newPassword')
                      }
                      type="password"
                      value={this.state.newPassword}
                      label="Password"
                      error={this.state.emailError}
                      helperText={this.state.emailErrorMessage}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      onClick={this.setPermanentPassword}
                      className={classes.button}
                    >
                      {this.state.formSubmitted ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <div>Submit</div>
                      )}
                    </Button>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }

    if (this.state.emailChecked) {
      if (!this.state.existingZohoRegistrant) {
        return (
          <div>
            <NavBar />
            <Grid
              style={{ paddingTop: '85px' }}
              container
              layout={'row'}
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12} sm={8} md={6}>
                <Banner
                  banner_location={this.props.event.event.banner_location}
                />
                <Paper className={classes.paper}>
                  <br />
                  <Typography variant="h5" style={{ color: 'black' }}>
                    Alright! Let's get you set up.
                  </Typography>
                  <br />
                  <Typography variant="body1" style={{ color: 'black' }}>
                    Please fill out all fields below and confirm you have
                    reviewed the Privacy Policy, then click Register
                  </Typography>
                  <br />
                  <FormControl
                    className={classes.formControl}
                    onKeyUp={(event) => {
                      if (event.key === 'Enter') this.handleZohoRegistration();
                    }}
                  >
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'first_name'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'first_name'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'firstName')
                          }
                          value={this.state.firstName}
                          label="First Name"
                          error={this.state.firstNameError}
                          helperText={this.state.firstNameErrorMessage}
                          autoFocus
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'last_name'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'last_name'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'lastName')
                          }
                          value={this.state.lastName}
                          label="Last Name"
                          error={this.state.lastNameError}
                          helperText={this.state.lastNameErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'department'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'department'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'department')
                          }
                          value={this.state.department}
                          label="Department"
                          error={this.state.departmentError}
                          helperText={this.state.departmentErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'job_title'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'job_title'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'jobTitle')
                          }
                          value={this.state.jobTitle}
                          label="Job Title"
                          error={this.state.jobTitleError}
                          helperText={this.state.jobTitleErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'company'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'company'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'company')
                          }
                          value={this.state.company}
                          label="Company"
                          error={this.state.companyError}
                          helperText={this.state.companyErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'site'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'site'
                            )[0].is_required
                          }
                          onChange={(event) => this.handleChange(event, 'site')}
                          value={this.state.site}
                          label="Site"
                          error={this.state.siteError}
                          helperText={this.state.siteErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'addressStreet'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'addressStreet'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'businessAddress')
                          }
                          value={this.state.businessAddress}
                          label="Business Address"
                          error={this.state.businessAddressError}
                          helperText={this.state.businessAddressErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'addressCity'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'addressCity'
                            )[0].is_required
                          }
                          onChange={(event) => this.handleChange(event, 'city')}
                          value={this.state.city}
                          label="City/Locality"
                          error={this.state.cityError}
                          helperText={this.state.cityErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'addressState'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'addressState'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'state')
                          }
                          value={this.state.state}
                          label="State/Province"
                          error={this.state.stateError}
                          helperText={this.state.stateErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'addressZip'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'addressZip'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'postalCode')
                          }
                          value={this.state.postalCode}
                          label="Postal Code"
                          error={this.state.postalCodeError}
                          helperText={this.state.postalCodeErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[0].filter(
                      (field) => field.name === 'phoneNumber'
                    ).length > 0 ? (
                      <>
                        <TextField
                          required={
                            this.state.registrationFields[0].filter(
                              (field) => field.name === 'phoneNumber'
                            )[0].is_required
                          }
                          onChange={(event) =>
                            this.handleChange(event, 'phone')
                          }
                          value={this.state.phone}
                          label="Phone Number"
                          error={this.state.phoneError}
                          helperText={this.state.phoneErrorMessage}
                        />
                        <br />
                      </>
                    ) : null}
                    {this.state.registrationFields[1].map((field, index) => {
                      let newIndex = index + 1;
                      let state1 = `custom${newIndex}`;
                      let state2 = `custom${newIndex}Error`;
                      let state3 = `custom${newIndex}ErrorMessage`;
                      return (
                        <React.Fragment key={field.field_id}>
                          <TextField
                            required={field.is_required}
                            onChange={(event) =>
                              this.handleChange(event, `custom${newIndex}`)
                            }
                            value={this.state[state1]}
                            label={field.name}
                            error={this.state[state2]}
                            helperText={this.state[state3]}
                          />
                          <br />
                        </React.Fragment>
                      );
                    })}
                    {this.state.registrationFields[2].map((field, index) => {
                      let newIndex = index + 1;
                      let state1 = `question${newIndex}`;
                      let state2 = `question${newIndex}Error`;
                      let state3 = `question${newIndex}ErrorMessage`;
                      return (
                        <React.Fragment key={field.field_id}>
                          <TextField
                            required={field.is_required}
                            onChange={(event) =>
                              this.handleChange(event, `question${newIndex}`)
                            }
                            value={this.state[state1]}
                            error={this.state[state2]}
                            helperText={this.state[state3]}
                            label={field.name}
                            fullWidth
                            multiline
                            rowsMax={4}
                          />
                          <br />
                        </React.Fragment>
                      );
                    })}
                    <TextField
                      required
                      onChange={(event) => this.handleChange(event, 'email')}
                      value={this.state.email}
                      label="Email"
                      disabled
                      error={this.state.emailError}
                      helperText={this.state.emailErrorMessage}
                    />
                  </FormControl>
                  {/* <FormControl className={classes.formControl}>
                                <TextField
                                    type="password"
                                    onChange={(event) => this.handleChange(event, "password")}
                                    value={this.state.password}
                                    label="Password"
                                    error = {this.state.parameterError}
                                    helperText={this.state.parameterErrorMessage}
                                />
                            </FormControl> */}
                  <FormControl style={{ margin: '24px 0px', width: '85%' }}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item xs={2} className={classes.toggleButton}>
                        <ToggleButton
                          value="check"
                          selected={this.state.privacySelected}
                          onChange={() => {
                            this.setState({
                              privacySelected: !this.state.privacySelected,
                            });
                          }}
                        >
                          <CheckIcon style={{ color: 'white' }} />
                        </ToggleButton>
                      </Grid>
                      <Grid item xs={6} style={{ padding: '0px 16px' }}>
                        <Typography variant="body2" color="textPrimary">
                          I have read and understand the Privacy Policy
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          variant="contained"
                          onClick={() => this.handleModal(true, 'privacyOpen')}
                          className={classes.button}
                          style={{ height: '100%', width: '80%' }}
                        >
                          Privacy
                          <br />
                          Policy
                        </Button>
                      </Grid>
                    </Grid>
                  </FormControl>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      onClick={this.handleZohoRegistration}
                      className={classes.button}
                    >
                      {this.state.formSubmitted ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <div>Register</div>
                      )}
                    </Button>
                    <FormHelperText id="component-helper-text" error>
                      {this.state.privacyAgree
                        ? 'Please agree to the privacy policy to continue'
                        : ''}
                    </FormHelperText>
                    <FormHelperText id="component-helper-text" error>
                      {this.state.globalError
                        ? 'Please rectify errors above'
                        : ''}
                    </FormHelperText>
                  </FormControl>
                  <DialogWrapper
                    open={this.state.privacyOpen}
                    handleClose={(e) => this.setState({ privacyOpen: false })}
                  >
                    <Privacy />
                    <Button
                      className={classes.acceptButton}
                      variant="contained"
                      onClick={(e) =>
                        this.setState({
                          privacyOpen: false,
                          privacySelected: true,
                        })
                      }
                    >
                      I Accept
                    </Button>
                    <Button
                      className={classes.closeButton}
                      variant="contained"
                      onClick={() => this.handleModal(false, 'privacyOpen')}
                    >
                      Close
                    </Button>
                  </DialogWrapper>
                </Paper>
              </Grid>
            </Grid>
            <Button
              className={classes.privacyButton}
              variant="contained"
              onClick={() => this.handleModal(true, 'privacyOpen')}
            >
              View{' '}
              <Hidden smUp>
                <br />
              </Hidden>
              Privacy{' '}
              <Hidden smUp>
                <br />
              </Hidden>
              Policy
            </Button>
          </div>
        );
      } else if (!this.state.existingCognitoUser) {
        return (
          <div>
            <NavBar />
            <Grid
              style={{ paddingTop: '85px' }}
              container
              layout={'row'}
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12} sm={8} md={6}>
                <Banner
                  banner_location={this.props.event.event.banner_location}
                />
                <Paper className={classes.paper}>
                  <br />
                  <Typography variant="h5" style={{ color: 'black' }}>
                    Perfect! Now let's make sure you can securely login
                  </Typography>
                  <br />
                  <Typography variant="body1" style={{ color: 'black' }}>
                    Please create a password using the form below
                  </Typography>
                  <br />
                  {/* <FormControl className={classes.formControl}>
                        <TextField
                            onChange={(event) => this.handleChange(event, "username")}
                            value={this.state.username}
                            label="Username"
                            fullWidth
                            error = {this.state.usernameError}
                            helperText={this.state.usernameError ? this.state.usernameErrorMessage : "Username cannot contain spaces"}
                        />
                    </FormControl> */}
                  <FormControl
                    className={classes.formControl}
                    onKeyUp={(event) => {
                      if (event.key === 'Enter') this.handleCognitoSignup();
                    }}
                  >
                    <TextField
                      onChange={(event) => this.handleChange(event, 'email')}
                      value={this.state.email}
                      label="Email"
                      disabled
                      error={this.state.emailError}
                      helperText={this.state.emailErrorMessage}
                    />
                    <br />
                    <TextField
                      type="password"
                      onChange={(event) => this.handleChange(event, 'password')}
                      value={this.state.password}
                      label="Password"
                      error={this.state.passwordError}
                      helperText={
                        this.state.passwordErrorMessage
                          ? this.state.passwordErrorMessage
                          : 'Password must be at least 8 characters long'
                      }
                      autoFocus
                    />
                  </FormControl>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      onClick={this.handleCognitoSignup}
                      className={classes.button}
                    >
                      {this.state.formSubmitted ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <div>Next</div>
                      )}
                    </Button>
                  </FormControl>
                </Paper>
                <DialogWrapper
                  open={this.state.mustConfirmEmail}
                  handleClose={() =>
                    this.handleModal(false, 'mustConfirmEmail')
                  }
                >
                  <Grid
                    style={{ paddingTop: '60px' }}
                    container
                    layout={'row'}
                    justifyContent="center"
                    spacing={0}
                  >
                    <Grid item xs={12} sm={8} md={6}>
                      <Typography variant="h5" style={{ color: 'black' }}>
                        Please check your inbox to confirm your email
                      </Typography>
                      <Typography variant="body1" style={{ color: 'black' }}>
                        If you cannot find the confirmation email, wait a few
                        minutes and check your spam folder. If the problem
                        persists, please reach out for chat support in the
                        bottom right of your screen.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Button
                    className={classes.closeButton}
                    variant="contained"
                    onClick={() => this.handleModal(false, 'mustConfirmEmail')}
                  >
                    Next
                  </Button>
                </DialogWrapper>
              </Grid>
            </Grid>
          </div>
        );
      } else {
        return (
          <div>
            <NavBar />
            <div className={classes.grid}>
              <Grid
                className={classes.gridBody}
                container
                layout={'row'}
                justifyContent="center"
                spacing={0}
              >
                <Grid item xs={12} sm={8} md={6}>
                  <Banner
                    banner_location={this.props.event.event.banner_location}
                  />
                  <Paper className={classes.paper}>
                    {this.state.justSignedUp ? (
                      <React.Fragment>
                        <br />
                        <Typography variant="h5" style={{ color: 'black' }}>
                          Beautiful! One last step.
                        </Typography>
                        <br />
                        <Typography variant="body1" style={{ color: 'black' }}>
                          Check your inbox for an email from
                          eregistration@planetconnect.com for your verification
                          link, then return here to sign in. The verification
                          link will only be valid for 1 hour.
                        </Typography>
                        <br />
                        {/* <FormControl className={classes.formControlButton}>
                                        <Button variant="contained" onClick={() => this.resendVerificationCode()} className={classes.button}>
                                            Resend Verification
                                        </Button>
                                    </FormControl> */}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <br />
                        <Typography variant="h5" style={{ color: 'black' }}>
                          Please input your password below to sign in.
                        </Typography>
                        <br />
                        <Typography variant="body1" style={{ color: 'black' }}>
                          If you have forgotten your password, you may reset it
                          below.
                        </Typography>
                        <br />
                      </React.Fragment>
                    )}

                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      onKeyUp={(event) => {
                        if (event.key === 'Enter') this.signIn();
                      }}
                    >
                      <TextField
                        onChange={(event) => this.handleChange(event, 'email')}
                        value={this.state.email}
                        label="Email"
                        error={this.state.emailError}
                        disabled
                      />
                      <br />
                      <TextField
                        onChange={(event) =>
                          this.handleChange(event, 'password')
                        }
                        type="password"
                        value={this.state.password}
                        label="Password"
                        error={this.state.passwordError}
                        helperText={this.state.passwordErrorMessage}
                        autoFocus
                      />
                    </FormControl>
                    <FormControl className={classes.formControlButton}>
                      <Button
                        variant="contained"
                        onClick={this.signIn}
                        className={classes.button}
                      >
                        {this.state.formSubmitted ? (
                          <CircularProgress color="inherit" />
                        ) : (
                          <div>Enter</div>
                        )}
                      </Button>
                    </FormControl>
                    <FormControl className={classes.formControlButton}>
                      <Button
                        color="inherit"
                        component={Link}
                        to={'/forgotpassword'}
                      >
                        Reset Password
                      </Button>
                    </FormControl>
                  </Paper>
                </Grid>
              </Grid>
            </div>
            <DialogWrapper
              open={this.state.privacyOpen}
              handleClose={() => this.handleModal(false, 'privacyOpen')}
            >
              <Privacy />
              <Button
                className={classes.closeButton}
                variant="contained"
                onClick={() => this.handleModal(false, 'privacyOpen')}
              >
                Close
              </Button>
            </DialogWrapper>
            <Button
              className={classes.privacyButton}
              variant="contained"
              onClick={() => this.handleModal(true, 'privacyOpen')}
            >
              View{' '}
              <Hidden smUp>
                <br />
              </Hidden>
              Privacy{' '}
              <Hidden smUp>
                <br />
              </Hidden>
              Policy
            </Button>
          </div>
        );
      }
    }

    let FirstName;
    let LastName;

    if (this.state.federatedId) {
      return (
        <div>
          <NavBar />
          <Grid
            style={{ paddingTop: '85px' }}
            container
            layout={'row'}
            justifyContent="center"
            spacing={0}
          >
            <Grid item xs={12} sm={8} md={6}>
              <Banner location={this.props.event.event.banner_location} />
              <Paper className={classes.paper}>
                <br />
                <Typography variant="h5" style={{ color: 'black' }}>
                  Welcome to the {this.props.event.event.title}!
                </Typography>
                <br />
                <Typography variant="body1" style={{ color: 'black' }}>
                  Enter your Merck email address to continue
                </Typography>
                <br />
                <FormControl
                  className={classes.formControl}
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') this.checkEmail();
                  }}
                >
                  <TextField
                    required
                    onChange={(event) => this.handleChange(event, 'email')}
                    value={this.state.email}
                    label="Email"
                    error={this.state.emailError}
                    helperText={this.state.emailErrorMessage}
                    autoFocus
                  />
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <TextField
                    onChange={(event) =>
                      this.handleChange(event, 'newPassword')
                    }
                    type="password"
                    value={this.state.newPassword}
                    label="Password"
                    error={this.state.emailError}
                    helperText={this.state.emailErrorMessage}
                  />
                </FormControl>
                <FormControl className={classes.formControlButton}>
                  <br />
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={() =>
                      Auth.federatedSignIn(
                        {
                          provider: 'Okta',
                        },
                        {
                          // the user object, you can put whatever property you get from the Auth0
                          // for example:
                          // the user name
                          FirstName, // Optional, the email address
                          LastName, // Optional, the phone number
                        }
                      )
                    }
                    className={classes.button}
                  >
                    {this.state.formSubmitted ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      <div>Check Merck Email</div>
                    )}
                  </Button>
                  <br />
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={() => this.setState({ federatedId: true })}
                    className={classes.button}
                    style={{ backgroundColor: '#008080' }}
                  >
                    Use Merck Login
                  </Button>
                  <br />
                </FormControl>
                <DialogWrapper
                  open={this.state.privacyOpen}
                  handleClose={(e) => this.setState({ privacyOpen: false })}
                >
                  <Privacy />
                  <Button
                    className={classes.acceptButton}
                    variant="contained"
                    onClick={(e) =>
                      this.setState({
                        privacyOpen: false,
                        privacySelected: true,
                      })
                    }
                  >
                    I Accept
                  </Button>
                  <Button
                    className={classes.closeButton}
                    variant="contained"
                    onClick={() => this.handleModal(false, 'privacyOpen')}
                  >
                    Close
                  </Button>
                </DialogWrapper>
              </Paper>
            </Grid>
          </Grid>
          <Button
            className={classes.privacyButton}
            variant="contained"
            onClick={() => this.handleModal(true, 'privacyOpen')}
          >
            View{' '}
            <Hidden smUp>
              <br />
            </Hidden>
            Privacy{' '}
            <Hidden smUp>
              <br />
            </Hidden>
            Policy
          </Button>
        </div>
      );
    }

    return (
      <div>
        <NavBar />
        <Grid
          style={{ paddingTop: '85px' }}
          container
          layout={'row'}
          justifyContent="center"
          spacing={0}
        >
          <Grid item xs={12} sm={8} md={6}>
            <Banner banner_location={this.props.event.event.banner_location} />
            <Paper className={classes.paper}>
              <br />
              <Typography variant="h5" style={{ color: 'black' }}>
                Welcome to the {this.props.event.event.title}!
              </Typography>
              <br />
              <Typography variant="body1" style={{ color: 'black' }}>
                Please login securely below.
              </Typography>
              {/* <Typography variant="body1">
                                Please click the button below to test PlanetConnect and Merck's SSO connection
                            </Typography> */}
              <br />
              {/* <FormControl
                className={classes.formControl}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') this.checkEmail();
                }}
              >
                <TextField
                  required
                  onChange={(event) => this.handleChange(event, 'email')}
                  value={this.state.email}
                  label="Email"
                  error={this.state.emailError}
                  helperText={this.state.emailErrorMessage}
                  autoFocus
                />
              </FormControl> */}
              <FormControl className={classes.formControlButton}>
                {/* <br />
                <Button
                  type="submit"
                  variant="contained"
                  onClick={this.checkEmail}
                  className={classes.button}
                >
                  {this.state.formSubmitted ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    <div>Next</div>
                  )}
                </Button> 
                <br /> */}
                {OktaSSOEnabled ? (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={() => Auth.federatedSignIn({ provider: 'Okta' })}
                      className={classes.button}
                    >
                      Login with Okta
                    </Button>
                    <br />
                  </>
                ) : null}
                {MerckSSOEnabled ? (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={() =>
                        Auth.federatedSignIn({ provider: 'Merck' })
                      }
                      className={classes.button}
                    >
                      Login with Merck SSO
                    </Button>
                    <br />
                  </>
                ) : null}
              </FormControl>
              <DialogWrapper
                open={this.state.privacyOpen}
                handleClose={(e) => this.setState({ privacyOpen: false })}
              >
                <Privacy />
                <Button
                  className={classes.acceptButton}
                  variant="contained"
                  onClick={(e) =>
                    this.setState({ privacyOpen: false, privacySelected: true })
                  }
                >
                  I Accept
                </Button>
                <Button
                  className={classes.closeButton}
                  variant="contained"
                  onClick={() => this.handleModal(false, 'privacyOpen')}
                >
                  Close
                </Button>
              </DialogWrapper>
            </Paper>
          </Grid>
        </Grid>
        <Button
          className={classes.privacyButton}
          variant="contained"
          onClick={() => this.handleModal(true, 'privacyOpen')}
        >
          View{' '}
          <Hidden smUp>
            <br />
          </Hidden>
          Privacy{' '}
          <Hidden smUp>
            <br />
          </Hidden>
          Policy
        </Button>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setAuthenticatedUser: setAuthenticatedUser,
      setLoginRedirect: setLoginRedirect,
      setBriefcase: setBriefcase,
      setEventDetails: setEventDetails,
      trackAction: trackAction,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(SSOonly)));
