import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, getAnonymous, setAnonymous } from '../Store/Reducers/user';
import { getEvent } from '../Store/Reducers/event';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import QRCodeScanner from '../Components/QRCodeScanner';
import routeQRCode from '../Store/Actions/routeQRCode';
import { colors } from '../config';
import { useHistory } from 'react-router-dom';
import parseSearchUrl from '../Utilities/parseSearchUrl';
import { Typography, Paper } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { Formik } from 'formik';
import { useMediaQuery, useTheme } from '@material-ui/core';

import actionScanAttendee from '../Store/Actions/actionScanAttendee';

export default function QRScanScreen() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUser);
  const event = useSelector(getEvent);
  const anonymous = useSelector(getAnonymous);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const parsedURL = parseSearchUrl(history.location.search);

  const [enableScan, setEnableScan] = useState(true);
  const [successText, setSuccessText] = useState(parsedURL?.success_text || '');
  const [errorText, setErrorText] = useState(parsedURL?.error_text || '');

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(setAnonymous(values));
  };

  const handleScanResults = async (decodedText, decodedResult) => {
    console.log(
      '🚀 ~ file: QRScanScreen.jsx ~ line 39 ~ handleScanResults ~ decodedText',
      decodedText
    );
    if (enableScan) {
      try {
        setEnableScan(false);

        const resultsText = await dispatch(routeQRCode(history, decodedText));

        if (resultsText) {
          if (resultsText === 'Attendee added to contacts.' && isMobile) {
            history.push('/engagementzone');
          }
          // Open a dialog
          setSuccessText(resultsText);
        } else {
          // resume scanning
          setEnableScan(true);
        }
      } catch (err) {
        setErrorText(err.message);
      }
    }
  };

  const handleClose = () => {
    setSuccessText('');
    setErrorText('');
    setEnableScan(true);
  };

  const allRoutes = JSON.parse(event.available_routes).routes;
  let pageTitle = '';
  allRoutes.forEach((route) => {
    if (route.route === '/scan') {
      pageTitle = route.title;
    }
  });

  return (
    <div>
      <Paper>
        <Typography
          style={{ textAlign: 'center', padding: '16px 0px 8px 0px' }}
          variant="h4"
          color="textPrimary"
        >
          {pageTitle}
        </Typography>
      </Paper>
      {/* <button
        onClick={() => {
          // 104233
          dispatch(actionScanAttendee(104233));
        }}
      >
        TEST BUTTON
      </button> */}
      <Typography style={styles.instructionText}>
        Please center the QR Code within the area below.
      </Typography>
      <div>
        <QRCodeScanner
          fps={10}
          qrbox={{ width: 250, height: 250 }}
          disableFlip={false}
          qrCodeSuccessCallback={handleScanResults}
          paused={!enableScan}
          aspectRatio={1}
        />
      </div>

      {(successText || errorText) && (
        <Dialog
          open
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {errorText ? 'Error' : 'Success'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {successText || errorText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {!anonymous && !user && (
        <Dialog
          open
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableEscapeKeyDown
          onBackdropClick={(reason) => {
            console.log(
              '🚀 ~ file: QRScanScreen.jsx ~ line 256 ~ QRScanScreen ~ reason',
              reason
            );
            return false;
          }}
        >
          <DialogTitle id="alert-dialog-title">Anonymous Voting</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please Enter the following information, this will be used to
              contact you if you win, but will not be shown publicly.
            </DialogContentText>
            <Formik
              initialValues={{ email: '', firstName: '', lastName: '' }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                if (!values.firstName) {
                  errors.firstName = 'Required';
                }
                if (!values.lastName) {
                  errors.lastName = 'Required';
                }
                return errors;
              }}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl style={styles.formControl} fullWidth>
                    <TextField
                      required
                      name="firstName"
                      onBlur={handleBlur}
                      value={values.firstName}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // value={anonymousInfo.firstName}
                      label="First Name"
                      error={touched.firstName && errors.firstName}
                      helperText={touched.firstName && errors.firstName}
                    />
                    <br />
                  </FormControl>
                  <FormControl style={styles.formControl} fullWidth>
                    <TextField
                      required
                      name="lastName"
                      onBlur={handleBlur}
                      value={values.lastName}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // value={anonymousInfo.lastName}
                      label="Last Name"
                      error={touched.lastName && errors.lastName}
                      helperText={touched.lastName && errors.lastName}
                    />
                    <br />
                  </FormControl>
                  <FormControl style={styles.formControl} fullWidth>
                    <TextField
                      required
                      type="email"
                      name="email"
                      onBlur={handleBlur}
                      value={values.email}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // value={anonymousInfo.email}
                      label="Email"
                      error={touched.email && errors.email}
                      helperText={touched.email && errors.email}
                    />
                  </FormControl>
                  <DialogActions>
                    <FormControl style={styles.formControlButton}>
                      <Button
                        onClick={handleSubmit}
                        color="primary"
                        autoFocus
                        disabled={isSubmitting}
                      >
                        OK
                      </Button>
                    </FormControl>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

const styles = {
  instructionText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: 15,
    fontSize: 25,
  },
  back: {
    color: colors.main,
    fontSize: 18,
  },
  formControl: {
    // margin: theme.spacing(2),
    minWidth: 120,
    width: '80%',
  },
  formControlButton: {
    // margin: theme.spacing(2),
  },
};
