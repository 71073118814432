import { eventId } from '../../config';
import { queryApi } from '../../Services/queryApi';
import { setConversation, setOpenConversation } from '../Reducers/twilio';
import addBriefcaseElement from './addBriefcaseElement';

export default function actionScanPoster(history, posterId) {
  return async (dispatch, getState) => {
    const poster = await dispatch(
      queryApi.endpoints.getPoster.initiate({
        eventId: eventId,
        posterId: posterId,
      })
    );

    if (!poster?.data) {
      throw new Error('poster does not exist, could not load poster');
    }
    const user = getState()?.user?.user;
    if (!user) {
      //TODO: handle logged out users
      throw new Error('user does not exist');
    }

    const briefcaseElements = await dispatch(
      queryApi.endpoints.getBriefcase.initiate(eventId)
    );

    const alreadyBriefcased = briefcaseElements?.data.find(
      (ele) => ele.resource_type === 'Poster' && ele.resource_id == posterId
    );

    if (!alreadyBriefcased) {
      let firstPresenter = null;
      if (poster.data.contacts) {
        poster.data.contacts.forEach((contact) => {
          if (
            contact.roles.includes('presenter') ||
            contact.roles.includes('Presenter')
          ) {
            if (!firstPresenter) {
              firstPresenter = {
                first_name: contact.first_name,
                last_name: contact.last_name,
                account_name: contact.account_name
                  ? contact.account_name
                  : contact.department,
              };
            }
          }
        });
      }

      await dispatch(
        addBriefcaseElement(
          'Poster',
          poster.data.abstract_id,
          poster.data.title,
          poster.data.account_id,
          user.account_id,
          poster.data.account_name,
          firstPresenter
        )
      );
    }
    history.push(`/posterhall?poster_id=${posterId}`);
  };
}
