import { landingPage } from '../config';
import Cookie from 'js-cookie';

export default function Banner({ banner_location }) {
  const cookie = Cookie.get('location');
  const banner = cookie === 'US/Canada' ? banner_location : landingPage.banner;

  console.log(banner_location);

  return (
    <img
      src={banner}
      alt="Banner Loading..."
      style={{
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        position: 'relative',
        padding: 0,
        margin: 0,
      }}
    />
  );
}
