import { queryApi } from '../../Services/queryApi';
import { setConversation, setOpenConversation } from '../Reducers/twilio';
import addBriefcaseElement from './addBriefcaseElement';
import twilioCreateJoinOneOnOneConversation from './twilioCreateJoinOneOnOneConversation';
import twilioSubscribeToIdentity from './twilioSubscribeToIdentity';
import { addTaskQueue } from '../Reducers/taskQueue';
import isOnline from '../../Utilities/isOnline';
import { getUser } from '../Reducers/user';
import trackAction from './tracker';

export default function actionScanAttendee(badgeKey) {
  return async (dispatch, getState) => {
    const online = isOnline(getState());

    if (!online) {
      dispatch(addTaskQueue({ task: 'Add Attendee', payload: { badgeKey } }));
      return 'Contact Saved. Will add to contact when online.';
    } else {
      const attendees = await dispatch(
        queryApi.endpoints.getAttendeeByBadgeNumber.initiate(badgeKey)
      );
      if (!attendees.data.length) {
        throw new Error(`Attendee with badge number ${badgeKey} not found`);
      }
      const attendee = attendees.data[0];

      // const attendee = directory.data.find(
      //   (attendee) => attendee.badge_key === badgeKey
      // );

      if (attendee) {
        // if (attendee.exhibition_id) {
        //   throw new Error(
        //     `Exhibitors should use lead retrieval system for badge scan.`
        //   );
        // }
        const conversationsClient = getState()?.twilio.conversationsClient;

        // const me = getState()?.user?.user;
        const me = getUser(getState());

        const user = getState()?.twilio?.users?.[attendee.email];

        if (
          conversationsClient &&
          attendee.email &&
          (!user || user?.identity !== attendee.email)
        ) {
          const subscribe = await dispatch(
            twilioSubscribeToIdentity(attendee.email)
          );
          const users = getState()?.twilio?.users;
        }

        const joinedChat = await dispatch(
          twilioCreateJoinOneOnOneConversation(attendee.email)
        );
        if (!joinedChat) {
          throw new Error(`Could not load attendee.`); //no twilio account
        }
        return 'Attendee added to contacts.';
      } else {
        throw new Error(`Could not load attendee.`);
      }
    }
  };
}
