import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { eventId } from '../../config';

import { viewPrivateFileCurrentWindow } from '../../Utilities/viewPrivateFile';

// import { useSelector } from 'react-redux';
import { useGetEventQuery } from '../../Services/queryApi';

export default function AgendaPDF(props) {
  // const classes = useStyles();
  const [agendaPdf, setAgendaPdf] = useState([]);
  // access redux to get strapi
  // const strapiPagesRedux = useSelector((state) => state.strapi);
  // const eventInformation = useSelector((state) => state.event);
  // configure menu item

  const { data, error, isLoading } = useGetEventQuery(eventId);

  function createiFrame(url) {
    let iframe = (
      <>
        <Typography variant="h4" style={{ marginBottom: '16px' }}>
          Agenda
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: props.content }} />
        <br />
        <iframe
          src={url}
          title={url}
          style={{ width: '100%', height: '80vh' }}
          frameBorder="0"
        ></iframe>
      </>
    );
    return iframe;
  }

  useEffect(() => {
    async function buildIframe() {
      if (data) {
        let iframePdf;
        if (props.user.roles.includes('Attendee')) {
          // display private agenda
          let signedPrivateAgenda = await viewPrivateFileCurrentWindow(
            data.agenda_private_location
          );
          iframePdf = await createiFrame(signedPrivateAgenda);

          setAgendaPdf(iframePdf);
        } else {
          // display public agenda
          iframePdf = createiFrame(data.agenda_public_location);
          setAgendaPdf(iframePdf);
        }
      }
    }
    buildIframe();
  }, [data]);

  //   return <div>Hello Exhibitor List</div>;
  return agendaPdf;
}
